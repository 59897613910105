/*
This function is used to update a single property of nested object by a "." separated path.
It returns a new object with the updated property. It does not mutate the original object.
Example:
const obj = {
  a: {
    b: {
      c: 1
    }
  }
  }
const updatedObj = updateObjectAtPath(obj, "a.b.c", 2);
*/

export function updateObjectAtPath<T>(obj: T, path: string, value: any): T {
  const keys = path.split(".");
  const [key, ...rest] = keys;

  if (rest.length > 0) {
    return {
      ...obj,
      [key]: updateObjectAtPath(obj[key], rest.join("."), value),
    };
  }

  return {
    ...obj,
    [key]: value,
  };
}

export function isEmpty(obj: Object): boolean {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return true;
}
