// Generates a union type for an integer range (zero inclusive)
// e.g. type x = TypedRange<5> will be 0|1|2|3|4
type TypedRange<
  T extends number,
  Result extends Array<Number> = []
> = Result["length"] extends T
  ? Result[number]
  : TypedRange<T, [...Result, Result["length"]]>;

export type Minute = TypedRange<60>;
export type Hour = TypedRange<24>;
export type Seconds = number;

export enum DayOfWeek {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 0,
}

export interface DateRange {
  start: Date;
  end: Date;
}

// Type alias for a wall clock datetime represented in UTC
export type WallClockDateTimeUtc = Date;

/** 
Allows to get nested keys of an object by dot notation.
Given an object like:
{
  a: {
    b: {
      c: 1
    }
  }
}
NestedKeys<typeof obj> will be:
"a" | "a.b" | "a.b.c"
**/
export type NestedKeys<T> = T extends Record<string, any>
  ? {
      [K in keyof T]-?:
        | `${Extract<K, string | number>}`
        | (T[K] extends any[]
            ? never
            : `${Extract<K, string | number>}.${NestedKeys<T[K]>}`);
    }[keyof T]
  : never;

export type Sorted<T, K extends string> = {
  sortOrder: number;
} & {
  [key in K]: T;
};
