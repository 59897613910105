import { Box, FormControl, Select, Typography, useTheme } from "@mui/material";

const InputSelect = ({ ...props }) => {
  let inputProps: any = {
    ...props,
    variant: props.variant || "standard",
    disableUnderline: true,
  };

  if (inputProps.variant === "outlined" && inputProps?.disableUnderline) {
    delete inputProps?.disableUnderline;
  }
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="body1"
        id={props.id}
        sx={{
          color: theme.palette.text.primary,
          fontSize: "0.7rem",
          padding: "0.5rem 0.3rem",
          mb: 0,
        }}
      >
        {props.title}
      </Typography>
      <FormControl error={props?.error}>
        <Select
          {...inputProps}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          }}
        />
      </FormControl>
    </Box>
  );
};

export default InputSelect;
