import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { IRoom, NendaProduct } from "../../../../../../../types/NendaTypes";
import { CustomerPortalState } from "../../../../store";

import { ExpandMore } from "@mui/icons-material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { OrganizationUnit } from "../../../../../../models/organizationUnit";
import { getCompanyIdByPremise } from "../../../../../../utils/company";
import { selectScreensByProduct } from "../../../../store/reducers/screenReducer";
import { CreatePromotionStateProps } from "./SelectRulesForm";
import { t } from "i18next";

const filterScreens = (
  premises: OrganizationUnit[],
  screens: IRoom[],
  routeValues: { id: string; resource: string },
  bookingValues: {
    promotionId?: string;
    companyId?: string;
    premiseId?: string;
  }
): IRoom[] => {
  // We are editing an existing booking
  if (bookingValues.promotionId) {
    // The booking is owned by the premise
    if (bookingValues.premiseId) {
      return screens.filter((s) => s.hotel === bookingValues.premiseId);
    }
    // The booking is owned by the company
    else {
      // We are handling the company booking on the premise route
      // Only allow screens for that premise
      if (routeValues.resource === "premise") {
        return screens.filter((s) => s.hotel === routeValues.id);
      }
      // We still filter since user might be admin with access to multiple companies
      const companyPremiseIds = premises
        .filter((p) => getCompanyIdByPremise(p) === bookingValues.companyId)
        .map((p) => p._id);
      return screens.filter((s) => companyPremiseIds.includes(s.hotel));
    }
  }
  // We are creating a new booking
  else {
    // We are on the premise route
    if (routeValues.resource === "premise") {
      // Filter by premise
      return screens.filter((s) => s.hotel === routeValues.id);
    }
    // We are on the company route
    if (routeValues.resource === "company") {
      // Filter by company
      const companyPremiseIds = premises
        .filter((p) => getCompanyIdByPremise(p) === routeValues.id)
        .map((p) => p._id);
      return screens.filter((s) => companyPremiseIds.includes(s.hotel));
    }
    // Unreachable state, but TS cant process that all values of the union type are handeled
    // So a return is needed for TS compiliance
    return [];
  }
};

export default ({ state, setState }: CreatePromotionStateProps) => {
  let { id, type } = useParams<{ id: string; type: string }>();
  const { promotionId: editPromotionId, companyId, premiseId } = state;
  const premises = useSelector((state: CustomerPortalState) => {
    return state.organizationUnits.premises.data;
  });

  const theme = useTheme();
  const allScreens = useSelector((state: CustomerPortalState) =>
    selectScreensByProduct(state, NendaProduct.SIGNAGE)
  );

  if (!type || !id) return null;

  const filteredScreens = filterScreens(
    premises,
    allScreens,
    { id, resource: type },
    { promotionId: editPromotionId, companyId, premiseId }
  );

  if (!filteredScreens) return null;

  // group all screens by hotel
  let groupedScreensByHotel: { [key: string]: IRoom[] } =
    filteredScreens.reduce((acc, screen) => {
      const hotel = screen.hotel;
      if (!acc[hotel]) {
        acc[hotel] = [];
      }
      acc[hotel].push(screen);
      return acc;
    }, {});

  // sort the grouped screens in alphabetic order by the screen.name
  for (let hotel in groupedScreensByHotel) {
    groupedScreensByHotel[hotel].sort((a, b) => {
      if (a.name < b.name) return -1;
      return 1;
    });
  }

  const handleSelectAll = () => {
    if (state.screens.length === filteredScreens.length) {
      setState((prev) => ({
        ...prev,
        screens: [],
      }));
      return;
    } else {
      setState((prev) => ({
        ...prev,
        screens: filteredScreens?.map((p) => p._id),
      }));
    }
  };

  const handleSelectAllFromGroup = (premiseId: string) => {
    const screenGroup = groupedScreensByHotel[premiseId].map((s) => s._id);
    const selectedScreensFromGroup = screenGroup.filter((s) =>
      state.screens.includes(s)
    );

    if (selectedScreensFromGroup.length === screenGroup.length) {
      const newScreens = state.screens.filter((s) => !screenGroup.includes(s));
      setState((prev) => ({
        ...prev,
        screens: newScreens,
      }));
    } else {
      const newScreens = [...new Set([...state.screens, ...screenGroup])];
      setState((prev) => ({
        ...prev,
        screens: newScreens,
      }));
    }
  };

  const [expanded, setExpanded] = useState<string[]>([
    Object.keys(groupedScreensByHotel)[0],
  ]);

  const handleExpand = (premiseId: string) => {
    if (expanded.includes(premiseId)) {
      setExpanded(expanded.filter((h) => h !== premiseId));
    } else {
      setExpanded([...expanded, premiseId]);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stack
        direction={"row"}
        gap={"0.5rem"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        py="0.5rem"
      >
        <Typography variant="caption">
          {Object.values(groupedScreensByHotel).length > 1
            ? t(
                "customerportal.pages.promotions.select_all_screens_on_premises"
              )
            : t("customerportal.pages.promotions.select_all_screens")}
        </Typography>
        {filteredScreens.length > 0 && (
          <Switch
            checked={state.screens.length === filteredScreens.length}
            onChange={handleSelectAll}
          />
        )}
      </Stack>
      {Object.values(groupedScreensByHotel)?.map((screens: IRoom[]) => {
        // Find out what key the screens are grouped by
        const premiseId =
          Object.keys(groupedScreensByHotel).find(
            (key) => groupedScreensByHotel[key] === screens
          ) || "";

        return (
          <Accordion
            defaultExpanded
            expanded={expanded.includes(premiseId)}
            key={premiseId}
          >
            <AccordionSummary
              sx={{ display: "flex" }}
              expandIcon={
                <ExpandMore onClick={() => handleExpand(premiseId)} />
              }
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box onClick={() => handleExpand(premiseId)}>
                  <Typography variant="body1">
                    {premises.find((p) => p._id === premiseId)?.name}
                  </Typography>{" "}
                  <Typography variant="body1" fontSize={"0.8rem"} color="gray">
                    {screens.length}
                    {screens.length > 1
                      ? ` ${t("common.screen.screen_other")}`
                      : ` ${t("common.screen.screen_one")}`}
                  </Typography>
                </Box>
                <Box
                  onClick={() => handleExpand(premiseId)}
                  sx={{ display: "flex", flex: 1 }}
                />
                <Box sx={{ px: "1rem" }}>
                  <FormControlLabel
                    sx={{
                      "&.MuiFormControl-root": {
                        margin: "0",
                      },
                    }}
                    label={t("common.select_all")}
                    checked={screens.every((s) =>
                      state.screens.includes(s._id)
                    )}
                    labelPlacement="end"
                    control={
                      <Checkbox
                        onChange={() => handleSelectAllFromGroup(premiseId)}
                        checked={screens.every((s) =>
                          state.screens.includes(s._id)
                        )}
                      />
                    }
                  />
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                p: "1rem",
                borderRadius: theme.shape.borderRadius,
                background: theme.palette.transparent.purple,
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "0.5rem",
                maxHeight: "20rem",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  backgroundColor: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: theme.palette.gradient.lightPurple,
                  borderRadius: "0.5rem",
                },
                [theme.breakpoints.down("md")]: {
                  gridTemplateColumns: "1fr",
                },
              }}
            >
              {screens?.map((screen) => (
                <ScreenCardSelect
                  screen={screen}
                  state={state}
                  setState={setState}
                  key={screen._id}
                />
              ))}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

const ScreenCardSelect = ({
  screen,
  state,
  setState,
}: {
  screen: IRoom;
  state;
  setState;
}) => {
  const theme = useTheme();
  const isSelected = state.screens.find((s) => s === screen._id) !== undefined;

  const handleSelect = () => {
    const selected = state.screens.find((s) => s === screen._id);
    if (selected) {
      setState((prev) => ({
        ...prev,
        screens: prev.screens.filter((s) => s !== screen._id),
      }));
    } else {
      setState((prev) => ({
        ...prev,
        screens: [...prev.screens, screen._id],
      }));
    }
  };

  return (
    <Box
      onClick={handleSelect}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
        background: isSelected
          ? theme.palette.transparent.blue
          : theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
        transition: "all 0.15s ease",
        p: "0.5rem",
        outline: `2px solid ${
          isSelected ? theme.palette.primary.light : "transparent"
        }`,
        "& .checkbox": {
          opacity: isSelected ? "1" : "0",
        },
        "&:hover": {
          outline: `2px solid ${theme.palette.primary.light}`,
          "& .checkbox": {
            opacity: "1",
          },
        },
      }}
    >
      <Typography variant="body1">{screen.name}</Typography>
      <Checkbox
        checked={isSelected}
        className="checkbox"
        sx={{
          padding: 0,
          color: theme.palette.primary.light + " !important",
          fill: theme.palette.primary.light + " !important",
        }}
      />
    </Box>
  );
};
