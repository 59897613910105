import { Epic, ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { subPagesService } from "../http/subPages.service";
import {
  AppState,
  CreateSubPageAction,
  DeleteSubPageAction,
  GetSubPagesAction,
  SetSubPagesAction,
  SubPageActions,
  SUBPAGES_ACTIONS,
  UpdateSubPageAction,
} from "../types/redux";
import { handleError } from "./error.redux";

export function GetSubPages(): GetSubPagesAction {
  return { type: SUBPAGES_ACTIONS.GET_SUBPAGES };
}

export function SetSubPages(subPages: any): SetSubPagesAction {
  return { type: SUBPAGES_ACTIONS.SET_SUBPAGES, subPages };
}

export function UpdateSubPage(id: string, payload: any): UpdateSubPageAction {
  return { type: SUBPAGES_ACTIONS.UPDATE_SUBPAGE, payload, id };
}

export function CreateSubPage(name: string): CreateSubPageAction {
  return { type: SUBPAGES_ACTIONS.CREATE_SUBPAGE, name };
}

export function DeleteSubPage(id: string): DeleteSubPageAction {
  return { type: SUBPAGES_ACTIONS.DELETE_SUBPAGE, id };
}

export default function subPagesReducer(state: AppState["subPages"] = { subPages: [] }, action: SubPageActions): AppState["subPages"] {
  switch (action.type) {
    case SUBPAGES_ACTIONS.SET_SUBPAGES:
      return {
        ...state,
        subPages: action.subPages,
      };
    default:
      return state;
  }
}

// Epics
const getSubPages$: Epic = (action$) => {
  return action$.pipe(
    ofType(SUBPAGES_ACTIONS.GET_SUBPAGES),
    switchMap(() => {
      return subPagesService.getSubPages().pipe(
        map((subPages) => {
          return SetSubPages(subPages);
        }),
        catchError(handleError)
      );
    })
  );
};

const updateSubPage$: Epic = (action$) => {
  return action$.pipe(
    ofType(SUBPAGES_ACTIONS.UPDATE_SUBPAGE),
    switchMap((a: UpdateSubPageAction) => {
      return subPagesService.updateSubPage(a.id, a.payload).pipe(
        map(() => {
          return GetSubPages();
        }),
        catchError(handleError)
      );
    })
  );
};

const createSubPage$: Epic = (action$) => {
  return action$.pipe(
    ofType(SUBPAGES_ACTIONS.CREATE_SUBPAGE),
    switchMap((a: CreateSubPageAction) => {
      return subPagesService.createSubPage(a.name).pipe(
        map(() => {
          return GetSubPages();
        }),
        catchError(handleError)
      );
    })
  );
};

const deleteSubPage$: Epic = (action$) => {
  return action$.pipe(
    ofType(SUBPAGES_ACTIONS.DELETE_SUBPAGE),
    switchMap((a: DeleteSubPageAction) => {
      return subPagesService.deleteSubPage(a.id).pipe(
        map(() => {
          return GetSubPages();
        }),
        catchError(handleError)
      );
    })
  );
};

export const subPagesEpics = [getSubPages$, updateSubPage$, createSubPage$, deleteSubPage$];
