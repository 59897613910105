import { Epic, ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { ITemplate } from "../../types/NendaTypes";
import { templatesService } from '../http/templates.service';
import { AppState, CreateTemplatesAction, DeleteTemplateAction, GetTemplatesAction, SetTemplatesAction, TemplatesActions, TEMPLATES_ACTIONS, UpdateTemplateAction } from "../types/redux";
import { handleError } from "./error.redux";

export function GetTemplates(): GetTemplatesAction {
  return { type: TEMPLATES_ACTIONS.GET_TEMPLATES };
}

export function SetTemplates(templates: Array<ITemplate>): SetTemplatesAction {
  return { type: TEMPLATES_ACTIONS.SET_TEMPLATES, templates };
}

export function CreateTemplate(name: string): CreateTemplatesAction {
  return { type: TEMPLATES_ACTIONS.CREATE_TEMPLATE, name };
}

export function DeleteTemplate(id: string): DeleteTemplateAction {
  return { type: TEMPLATES_ACTIONS.DELETE_TEMPLATE, id };
}

export function UpdateTemplate(id: string, data: Partial<ITemplate>): UpdateTemplateAction {
  return { type: TEMPLATES_ACTIONS.UPDATE_TEMPLATE, id, data };
}

export default function templatesReducer(state: AppState["templates"] = [], action: TemplatesActions): AppState["templates"] {
  switch (action.type) {
    case TEMPLATES_ACTIONS.SET_TEMPLATES:
      return action.templates;
    default:
      return state;
  }
}

const getTemplates$: Epic = (action$) => {
  return action$.pipe(
    ofType(TEMPLATES_ACTIONS.GET_TEMPLATES),
    switchMap(() => {
      return templatesService.getTemplates().pipe(
        map((templates) => SetTemplates(templates)),
        catchError(handleError)
      );
    })
  );
};

const createTemplates$: Epic = (action$) => {
  return action$.pipe(
    ofType(TEMPLATES_ACTIONS.CREATE_TEMPLATE),
    switchMap(({ name }: CreateTemplatesAction) => {
      return templatesService.postTemplate({ name }).pipe(
        map(() => GetTemplates()),
        catchError(handleError)
      );
    })
  );
};

const updateTemplate$: Epic = (action$) => {
  return action$.pipe(
    ofType(TEMPLATES_ACTIONS.UPDATE_TEMPLATE),
    switchMap(({ id, data }: UpdateTemplateAction) => {
      return templatesService.putTemplate(id, data).pipe(
        map(() => GetTemplates()),
        catchError(handleError)
      );
    })
  );
};

const deleteTemplate$: Epic = (action$) => {
  return action$.pipe(
    ofType(TEMPLATES_ACTIONS.DELETE_TEMPLATE),
    switchMap(({ id }: DeleteTemplateAction) => {
      return templatesService.deleteTemplate(id).pipe(
        map(() => GetTemplates()),
        catchError(handleError)
      );
    })
  );
};

export const templatesEpics = [getTemplates$, createTemplates$, updateTemplate$, deleteTemplate$];