export function getTitle(asset, locales) {
  const localizedWithTitles = asset.localized.filter((loc) => loc.title);
  if (localizedWithTitles.length === 0) return "";
  const preferredLocalized = localizedWithTitles.filter((loc) => locales.includes(loc.locale));
  if (preferredLocalized.length === 0) {
    return localizedWithTitles[0].title;
  }
  const sorted = preferredLocalized.sort((a, b) => locales.indexOf(a.locale) - locales.indexOf(b.locale));
  return sorted[0].title;
}

export default {
  getTitle,
};
