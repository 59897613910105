export const isUrl = (str: string) => {
  var urlPattern = new RegExp(
    "^(https:\\/\\/)" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$", // validate fragment locator
    "i"
  );
  return urlPattern.test(str);
};

export function getSubDomain() {
  return window.location.host.split(".")[0];
}

export function isProduction() {
  const nonProductionPrefixes = ["stage", "dev", "localhost", "127.0.0.1"];
  return !nonProductionPrefixes.some((v) => getSubDomain().includes(v));
}
