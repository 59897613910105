import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { CarouselUtils } from "@shared/carousel";
import {
  CarouselAssetType,
  ICarousel,
  ICarouselWithAsset,
  LogicConnectors,
} from "../../../../types/NendaTypes";
import { AppState } from "../../../types/redux";
import { AutocompleteTagsComponent } from "../../AutocompleteTags/AutocompleteTags";
import { StringArrayEdit } from "../../StringArrayEdit/StringArrayEdit";
import {
  ConditionalSectionWrapper,
  SectionWrapper,
} from "../../UI/SectionWrapper/SectionWrapper";
import sharedConstants from "@shared/constants";
import { assetService } from "../../../http/asset.service";
import assetUtils from "@shared/assetUtils";
import { Asset } from "../../../models/asset";
import { FaPlus } from "react-icons/fa";
import { Select, MenuItem } from "@mui/material";

interface ITagQueryWidgetProps {
  carousel: ICarousel;
  onChange(value: Partial<ICarouselWithAsset>): void;
}

export const TagQueryWidget: React.FC<ITagQueryWidgetProps> = ({
  carousel,
  onChange,
}) => {
  const [carouselChanged, setCarouselChanged] = React.useState(false);
  const [selectedAssetTypes, setSelectedAssetTypes] = React.useState<
    CarouselAssetType[]
  >([]);
  const [selectedFilterChannel, setSelectedFilterChannel] = React.useState<
    { name: string; value: string } | undefined
  >();
  const [channelFilterValues, setChannelFilterValues] = React.useState<
    { name: string; value: string }[]
  >([]);
  const [channels, setChannels] = React.useState<Asset[]>([]);
  useEffect(() => {
    const sub = assetService
      .getAssetsByQuery("type:TV_CHANNEL", "-created")
      .subscribe((channels) => {
        setChannels(channels);
        sub.unsubscribe();
      });
  }, []);
  const availableTags = useSelector((state: AppState) =>
    state.tags?.tags.map((t) => {
      const localeItem = t.localized.find((l) => l.locale === "en");
      return {
        ...t,
        name: localeItem ? localeItem.title : t.tagId,
        value: t.tagId,
      };
    })
  );

  const handleAssetTypeCheck = (event) => {
    var updatedList = [...selectedAssetTypes];
    if (event.target.checked) {
      updatedList = [...selectedAssetTypes, event.target.value];
    } else {
      updatedList.splice(selectedAssetTypes.indexOf(event.target.value), 1);
    }
    setSelectedAssetTypes(updatedList);
    onChange({ assetTypeFilter: updatedList });
  };

  useEffect(() => {
    setCarouselChanged(true);
    if (carousel.assetTypeFilter)
      setSelectedAssetTypes(carousel.assetTypeFilter);
  }, [carousel]);
  return (
    <>
      <ConditionalSectionWrapper>
        <SectionWrapper>
          <h4>Asset type</h4>
          {/*<select
            value={carousel.assetTypeFilter}
            onChange={(e) => {
              onChange({
                assetTypeFilter: e.target.value,
                query: CarouselUtils.getQuery(
                  { ...carousel, assetTypeFilter: e.target.value },
                  availableTags
                ),
              });
            }}
            className="form-control"
          >*/}
          {/*<option value={"*"}>All</option>*/}
          {sharedConstants.CAROUSEL_ASSET_TYPES.map((item, index) => (
            <div className="form-check" key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                value={item}
                checked={selectedAssetTypes.includes(item)}
                id={"assetTypeCheck" + index}
                onChange={handleAssetTypeCheck}
              />
              <label
                className="form-check-label"
                htmlFor={"assetTypeCheck" + index}
              >
                {sharedConstants.ASSET_TYPES[item]}
              </label>
            </div>
          ))}
        </SectionWrapper>
        {selectedAssetTypes.includes("LIVE_EVENT") && (
          <SectionWrapper>
            <h4>Live event include channels (all if empty)</h4>
            <div className="input-group">
              <Select
                className="form-control"
                onChange={(e) => {
                  if (e.target.value)
                    setSelectedFilterChannel({
                      name: e.target.name,
                      value: e.target.value as string,
                    });
                }}
              >
                <MenuItem value="" />
                {channels
                  .map((c) => ({
                    name: assetUtils.getTitle(c, ["en", "sv"]),
                    id: c.assetId,
                  }))
                  .map((c) => (
                    <MenuItem value={c.id} key={c.id}>
                      {c.name}
                    </MenuItem>
                  ))}
              </Select>
              <button
                disabled={selectedFilterChannel === undefined}
                className="btn btn-secondary"
                onClick={() => {
                  if (selectedFilterChannel) {
                    const newValues = [
                      ...channelFilterValues,
                      selectedFilterChannel!,
                    ];
                    setChannelFilterValues(newValues);
                    onChange({
                      liveEventChannelFilter: newValues.map((f) => f.value),
                    });
                  }
                }}
              >
                <FaPlus />
              </button>
            </div>
            <StringArrayEdit
              values={channelFilterValues}
              onChange={(
                updatedChannelFilterValues: { name: string; value: string }[]
              ) => {
                setChannelFilterValues(updatedChannelFilterValues);
                onChange({
                  liveEventChannelFilter: updatedChannelFilterValues.map(
                    (f) => f.value
                  ),
                });
              }}
            />
          </SectionWrapper>
        )}
        <SectionWrapper>
          <h4>Sort property</h4>
          <Select
            onChange={(e) => onChange({ sort: e.target.value })}
            value={carousel.sort}
            className="form-control"
          >
            <MenuItem value="-created">Most recent</MenuItem>
            <MenuItem value="created">Oldest</MenuItem>
            <MenuItem value="-popularityScores.180">Popularity</MenuItem>
            <MenuItem value="localized.en.sortingTitle">Sorting Title</MenuItem>
          </Select>
        </SectionWrapper>
        <SectionWrapper>
          <h4>Grouping</h4>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={carousel.groupByTitle}
              id="groupbytitle"
              onChange={(e) => onChange({ groupByTitle: e.target.checked })}
            />
            <label className="form-check-label" htmlFor="groupbytitle">
              Group assets by title
            </label>
          </div>
        </SectionWrapper>
        <SectionWrapper>
          <h4>Filter</h4>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={carousel.filterDuplicates}
              id="filterduplicates"
              onChange={(e) => onChange({ filterDuplicates: e.target.checked })}
            />
            <label className="form-check-label" htmlFor="filterduplicates">
              Filter duplicates by name
            </label>
          </div>
        </SectionWrapper>
        <SectionWrapper>
          <h4>
            Tags & Genres{" "}
            <span className="description">
              These are filtering, i.e. the assets included will have ALL the
              choosen tags.
            </span>
          </h4>
          <div>
            <label htmlFor="logicConnectorSelect">Logic connector</label>
            <Select
              id="logicConnectorSelect"
              className="form-control col-3 mb-2"
              onChange={(e) =>
                onChange({
                  logicConnector: e.target.value as LogicConnectors,
                  query: CarouselUtils.getQuery(
                    {
                      ...carousel,
                      logicConnector: e.target.value as LogicConnectors,
                    },
                    availableTags
                  ),
                })
              }
              defaultValue={carousel.logicConnector || LogicConnectors.AND}
            >
              {Object.values(LogicConnectors).map((lc, index) => (
                <MenuItem value={lc} key={index}>
                  {lc}
                </MenuItem>
              ))}
            </Select>
          </div>
          <AutocompleteTagsComponent
            onSelect={(value) =>
              onChange({
                tagIds: [...carousel.tagIds, value],
                query: CarouselUtils.getQuery(
                  { ...carousel, tagIds: [...carousel.tagIds, value] },
                  availableTags
                ),
              })
            }
            tags={availableTags || []}
            carouselTagIds={carousel.tagIds}
            clearSearchTerm={carouselChanged}
          />
          <StringArrayEdit
            onChange={(values) =>
              onChange({
                tagIds: values,
                query: CarouselUtils.getQuery(
                  { ...carousel, tagIds: values },
                  availableTags
                ),
              })
            }
            values={carousel.tagIds.map(
              (tagId) =>
                availableTags?.find((tag) => tag.value === tagId) || {
                  name: tagId,
                  value: tagId,
                  className: "bg-warning",
                }
            )}
          />
          <small>
            Yellow background: Tag not found. Ignored when doing query{" "}
          </small>
        </SectionWrapper>
      </ConditionalSectionWrapper>
    </>
  );
};
