import { httpClient, Service } from "./client";
import { map } from "rxjs/operators";
import { fromJson } from "../models/modelHelpers";
import { Report } from "../models/report";

class ReportService extends Service {
  postReport(hotelIds: Array<string>, from: string, to: string) {
    return httpClient.post(`/reports`, { hotelIds, from, to }, this.authHeader()).pipe(map((report) => fromJson(Report, report)));
  }

  postReportAll(from: string, to: string) {
    return httpClient.post(`/reports/all`, { from, to }, this.authHeader()).pipe(map((report) => fromJson(Report, report)));
  }
}

export const reportService = new ReportService();
