import React, { ReactNode } from "react";

interface IConditionalSectionWrapperProps {
  children?: ReactNode;
  className?: string;
}

export const ConditionalSectionWrapper: React.FC<IConditionalSectionWrapperProps> = ({ children, className }) => {
  return <div className={"bg-light p-3 mb-3 border border-primary rounded " + (className || "")}>{children}</div>;
};

interface ISectionWrapperProps {
  children?: ReactNode;
  className?: string;
}

export const SectionWrapper: React.FC<ISectionWrapperProps> = ({ children, className }) => {
  return <div className={"my-2 py-3 " + (className || "")}>{children}</div>;
};
