import { createHashHistory } from "history";
import { routerMiddleware, routerReducer } from "react-router-redux";
import {
  Action,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from "redux";
import { combineEpics, createEpicMiddleware } from "redux-observable";
import {
  AppState,
  AUTH_ACTIONS,
  CAROUSEL_ACTIONS,
  ORGANIZATION_UNITS_ACTIONS,
  PRODUCT_OFFERING_ACTIONS,
  SUBPAGES_ACTIONS,
} from "../types/redux";
import assetReducer, { assetEpics } from "./asset.redux";
import authReducer, { authEpics } from "./auth.redux";
import carouselsReducer, { carouselEpics } from "./carousels.redux";
import configReducer, { configEpics } from "./config.redux";
import errorReducer from "./error.redux";
import notificationReducer from "./notification.redux";
import ouReducer, {
  organizationUnitEpics,
  organizationUnitsInitialState,
} from "./organizationUnit.redux";
import poReducer, { productOfferingEpics } from "./productOfferings.redux";
import subPagesReducer, { subPagesEpics } from "./subPages.redux";
import tagReducer, { tagsEpics } from "./tags.redux";
import templatesReducer, { templatesEpics } from "./templates.redux";
import upsalesReducer, { upsalesEpics } from "./upsales.redux";
import utilReducer, { utilEpics } from "./util.redux";

export const effectRunner = createEpicMiddleware();

export const rootEpic = combineEpics(
  ...tagsEpics,
  // Carousels
  ...carouselEpics,
  // OrganizationUnits
  ...organizationUnitEpics,
  //ProductOfferings
  ...productOfferingEpics,
  // Auth
  ...authEpics,
  // config
  ...configEpics,
  // subPages
  ...subPagesEpics,
  // assets,
  ...assetEpics,
  // util
  ...utilEpics,
  // templates
  ...templatesEpics,
  // upsales
  ...upsalesEpics
);

export const history = createHashHistory();
const middleware = routerMiddleware(history);

function loadingReducer(
  state: AppState["loading"],
  action: Action
): AppState["loading"] {
  switch (action.type) {
    case ORGANIZATION_UNITS_ACTIONS.UPDATE_ROOM:
    case ORGANIZATION_UNITS_ACTIONS.GET_ORGANIZATION_UNITS:
    case ORGANIZATION_UNITS_ACTIONS.DELETE_ORGANIZATION_UNIT:
    case ORGANIZATION_UNITS_ACTIONS.CREATE_ORGANIZATION_UNIT:
    case PRODUCT_OFFERING_ACTIONS.GET_PRODUCT_OFFERINGS:
    case CAROUSEL_ACTIONS.GET_CAROUSELS:
    case CAROUSEL_ACTIONS.CREATE_CAROUSEL:
    case CAROUSEL_ACTIONS.UPDATE_CAROUSEL:
    case CAROUSEL_ACTIONS.DELETE_CAROUSEL:
    case AUTH_ACTIONS.LOGIN:
    case ORGANIZATION_UNITS_ACTIONS.CREATE_ROOMS:
    // case ORGANIZATION_UNITS_ACTIONS.GET_ROOMS:
    case SUBPAGES_ACTIONS.GET_SUBPAGES:
    case SUBPAGES_ACTIONS.UPDATE_SUBPAGE:
    case SUBPAGES_ACTIONS.CREATE_SUBPAGE:
    case SUBPAGES_ACTIONS.DELETE_SUBPAGE:
      return true;
    default:
      return false;
  }
}

let initialState: any = {
  // let initialState: AppState = {
  loading: false,
  carousels: {
    carousels: [],
    loading: false,
  },
  error: {
    currentError: undefined,
  },
  notification: {
    currentNotification: null,
  },
  organizationUnits: organizationUnitsInitialState,
  productOfferings: {
    productOfferings: [],
    loading: false,
    productOffering: {}, // the product offering shown in detail page
  },
  tags: {
    tags: [],
  },
  auth: {
    session: {
      sessionToken: localStorage.getItem("sessionToken"),
      type: localStorage.getItem("userType"),
      expiration: localStorage.getItem("expiration"),
    },
  },
  routing: null,
  config: {
    languages: [],
  },
  subPages: {
    subPages: [],
  },
  assets: {
    autocomplete: [],
    assetsPerCarousel: [],
  },
  templates: [],
  upsales: {
    companies: [],
    subscriptions: [],
  },
};

const reducers = combineReducers({
  loading: loadingReducer,
  carousels: carouselsReducer,
  error: errorReducer,
  organizationUnits: ouReducer,
  tags: tagReducer,
  auth: authReducer,
  routing: routerReducer,
  config: configReducer,
  subPages: subPagesReducer,
  assets: assetReducer,
  productOfferings: poReducer,
  notification: notificationReducer,
  util: utilReducer,
  templates: templatesReducer,
  upsales: upsalesReducer,
});

const composeEnhancers =
  window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] || compose;

export const store = createStore(
  reducers,
  initialState,
  composeEnhancers(applyMiddleware(effectRunner, middleware))
);

effectRunner.run(rootEpic);
