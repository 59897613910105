import { ApiLabelRule, ApiProductOfferings } from "../types/RedBeeTypes";

/**
 * Returns an arrays of rules, useful when looking for roomTypes
 * @param rules label rules
 * @returns array
 */
export function flattenRules(rules: ApiProductOfferings['labelRule']): Array<ApiLabelRule> {
  if (rules.and || rules.or) {
    return (rules.and || rules.or || []).reduce((result, rule) => [...result, ...flattenRules(rule)], [] as Array<ApiLabelRule>);
  } else if (rules.eq) {
    return [rules];
  }
  return [];
}

/**
 * Checks if a room type ID is part of a product offering
 * @param po Product offering
 * @param roomTypeId string
 * @returns boolean
 */
export function findRoomTypeOnRules(po: ApiProductOfferings, roomTypeId: string): boolean {
  return po.labelRule && !!flattenRules(po.labelRule)?.find(({ eq }) => eq?.key === "roomType" && eq?.value === roomTypeId);
}

/**
 * Calculates the final price of a product offering, applying taxes if needed
 * @param po 
 * @returns float
 */
export function getFinalPrice(po: ApiProductOfferings): number | null {
  if (!po.offeringPrice) {
    return null;
  }
  let amount = po.offeringPrice.price.amount / Math.pow(10, po.offeringPrice.price.fractionDigits);
  // if po?.offeringPrice?.vat?.included === false means that the vat is not included and it must be calculated
  amount += po?.offeringPrice?.vat?.included === false ? amount * (po?.offeringPrice?.vat?.percentage / 100) : 0;
  return amount;
}

export default {
  flattenRules,
  findRoomTypeOnRules,
};
