import { Epic, ofType } from "redux-observable";
import { createSelector } from "reselect";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { CustomerPortalState } from "..";
import { FileUploadResponse } from "../../../../../server/services/fileService";
import { NestedKeys } from "../../../../../types/HelperTypes";
import {
  FileUploadScope,
  FileUploadType,
  IContentChannel,
  OverlayPosition,
} from "../../../../../types/NendaTypes";
import { contentChannelService } from "../../../../http/contentChannel.service";
import {
  AddContentChannelAction,
  AddContentChannelFailureAction,
  AddContentChannelSuccessAction,
  CONTENTCHANNEL_ACTIONS,
  ContentChannelActions,
  DeleteContentChannelAction,
  DeleteContentChannelFailureAction,
  DeleteContentChannelSuccessAction,
  GetContentChannelsAction,
  GetContentChannelsFailureAction,
  GetContentChannelsSuccessAction,
  UpdateContentChannelAction,
  UpdateContentChannelFailureAction,
  UpdateContentChannelSuccessAction,
  UploadFileAction,
  UploadLogoSuccessAction,
  UploadPosterSuccessAction,
} from "../../../../types/redux";
import { SetError, handleError } from "./errorReducer";
import { SetNotification } from "./notificationReducer";

export interface ContentChannelState {
  channels: IContentChannel[];
  isLoading: boolean;
}

export const initialState: ContentChannelState = {
  channels: [],
  isLoading: false,
};

export function GetContentChannels(): GetContentChannelsAction {
  return { type: CONTENTCHANNEL_ACTIONS.GET_CONTENTCHANNELS };
}

function GetContentChannelsSuccess(
  channels: IContentChannel[]
): GetContentChannelsSuccessAction {
  return {
    type: CONTENTCHANNEL_ACTIONS.GET_CONTENTCHANNELS_SUCCESS,
    channels,
  };
}

function GetContentChannelsFailure(
  error: any
): GetContentChannelsFailureAction {
  return { type: CONTENTCHANNEL_ACTIONS.GET_CONTENTCHANNELS_FAILURE, error };
}

export function AddContentChannel(data): AddContentChannelAction {
  return { data, type: CONTENTCHANNEL_ACTIONS.ADD_CONTENTCHANNEL };
}

function AddContentChannelSuccess(
  channels: IContentChannel[]
): AddContentChannelSuccessAction {
  return {
    type: CONTENTCHANNEL_ACTIONS.ADD_CONTENTCHANNEL_SUCCESS,
  };
}

function AddContentChannelFailure(error: any): AddContentChannelFailureAction {
  return { type: CONTENTCHANNEL_ACTIONS.ADD_CONTENTCHANNEL_FAILURE, error };
}

export function UpdateContentChannel(
  channel: Partial<IContentChannel>,
  unsetFields: NestedKeys<IContentChannel>[] = []
): UpdateContentChannelAction {
  return {
    request: { data: channel, unsetFields: unsetFields },
    type: CONTENTCHANNEL_ACTIONS.UPDATE_CONTENTCHANNEL,
  };
}

function UpdateContentChannelSuccess(
  channels: IContentChannel[]
): UpdateContentChannelSuccessAction {
  return {
    type: CONTENTCHANNEL_ACTIONS.UPDATE_CONTENTCHANNEL_SUCCESS,
  };
}

function UpdateContentChannelFailure(
  error: any
): UpdateContentChannelFailureAction {
  return { type: CONTENTCHANNEL_ACTIONS.UPDATE_CONTENTCHANNEL_FAILURE, error };
}

export function DeleteContentChannel(channelId): DeleteContentChannelAction {
  return { channelId, type: CONTENTCHANNEL_ACTIONS.DELETE_CONTENTCHANNEL };
}

function DeleteContentChannelSuccess(
  channels: IContentChannel[]
): DeleteContentChannelSuccessAction {
  return {
    type: CONTENTCHANNEL_ACTIONS.DELETE_CONTENTCHANNEL_SUCCESS,
  };
}

function DeleteContentChannelFailure(
  error: any
): DeleteContentChannelFailureAction {
  return { type: CONTENTCHANNEL_ACTIONS.DELETE_CONTENTCHANNEL_FAILURE, error };
}

export function UploadFile(
  channelId: string,
  image: File,
  scope: FileUploadScope
): UploadFileAction {
  return { channelId, image, type: CONTENTCHANNEL_ACTIONS.UPLOAD_LOGO, scope };
}

export function UploadLogoSuccess(
  channelId: string,
  uploadImageResponse: FileUploadResponse
): UploadLogoSuccessAction {
  return {
    channelId,
    uploadImageResponse,
    type: CONTENTCHANNEL_ACTIONS.UPLOAD_LOGO_SUCCESS,
  };
}
export function UploadPosterSuccess(
  channelId: string,
  uploadImageResponse: FileUploadResponse
): UploadPosterSuccessAction {
  return {
    channelId,
    uploadImageResponse,
    type: CONTENTCHANNEL_ACTIONS.UPLOAD_POSTER_SUCCESS,
  };
}

// Selectors
export const selectContentChannel = createSelector(
  [
    (state: CustomerPortalState) => state.contentChannel.channels,
    (_state, channelId: string) => channelId,
  ],
  (channels, channelId) => channels.find((c) => c._id === channelId)
);

export const selectContentChannelsByNavigatedPremise = createSelector(
  [
    (state: CustomerPortalState) => state.organizationUnits.premises.data,
    (state: CustomerPortalState) => state.contentChannel.channels,
    (state: CustomerPortalState) => state.workspace.scope.premise,
  ],
  (premisesData, channels, premiseId) => {
    const premise = premisesData.find((p) => p._id === premiseId);
    if (!premise) return [];
    return (
      channels.filter((c) => premise.contentChannels?.includes(c._id)) || []
    );
  }
);

export const selectCategorizedContentChannelsByNavigatedPremise =
  createSelector(
    [
      (state: CustomerPortalState) => state.organizationUnits.premises.data,
      selectContentChannelsByNavigatedPremise,
    ],
    (premises, contentChannels) => {
      const categorizedContentChannels = contentChannels.reduce(
        (acc, channel) => {
          let category = channel.category;

          if (channel.playlist[0]?.type === "linearchannel") {
            category = "TV Channels";
          }

          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(channel);

          return acc;
        },
        {}
      );

      //Move TV Channels to the bottom
      let tvChannels = { ...categorizedContentChannels };
      if (categorizedContentChannels["TV Channels"]) {
        delete tvChannels["TV Channels"];

        tvChannels["TV Channels"] = categorizedContentChannels["TV Channels"];
      }

      return tvChannels;
    }
  );

export const selectContentChannels = (
  state: CustomerPortalState
): IContentChannel[] => {
  return state.contentChannel.channels;
};

export const selectContentChannelById = createSelector(
  (state: CustomerPortalState) => state.contentChannel.channels,
  (_state: CustomerPortalState, channelId: string) => channelId,
  (channels, channelId) => channels.find((c) => c._id === channelId)
);

export const selectContentChannelsByIds = createSelector(
  (state: CustomerPortalState) => state.contentChannel.channels,
  (_state: CustomerPortalState, channelIds: string[]) => channelIds,
  (channels, channelIds) => {
    return channels.filter((c) => channelIds.includes(c._id));
  }
);

export const selectIsLoading = (state: CustomerPortalState) =>
  state.contentChannel.isLoading;

// Reducer
export default function contentChannelReducer(
  state: ContentChannelState = initialState,
  action: ContentChannelActions
): ContentChannelState {
  switch (action.type) {
    case CONTENTCHANNEL_ACTIONS.GET_CONTENTCHANNELS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        channels: action.channels,
      };
    case CONTENTCHANNEL_ACTIONS.GET_CONTENTCHANNELS:
    case CONTENTCHANNEL_ACTIONS.ADD_CONTENTCHANNEL:
    case CONTENTCHANNEL_ACTIONS.UPDATE_CONTENTCHANNEL:
    case CONTENTCHANNEL_ACTIONS.DELETE_CONTENTCHANNEL:
    case CONTENTCHANNEL_ACTIONS.UPLOAD_LOGO:
      return {
        ...state,
        isLoading: true,
      };
    case CONTENTCHANNEL_ACTIONS.ADD_CONTENTCHANNEL_SUCCESS:
    case CONTENTCHANNEL_ACTIONS.UPDATE_CONTENTCHANNEL_SUCCESS:
    case CONTENTCHANNEL_ACTIONS.DELETE_CONTENTCHANNEL_SUCCESS:
    case CONTENTCHANNEL_ACTIONS.UPLOAD_LOGO_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case CONTENTCHANNEL_ACTIONS.UPLOAD_LOGO_SUCCESS:
      const updatedChannels = state.channels.map((c) => {
        if (c._id === action.channelId) {
          return {
            ...c,
            logo: {
              position: OverlayPosition.BOTTOM_LEFT,
              locator: action.uploadImageResponse.fileUrl,
              useInPlayer: true,
            },
          };
        }
        return c;
      });
      return {
        ...state,
        isLoading: false,
        channels: updatedChannels,
      };
    case CONTENTCHANNEL_ACTIONS.UPLOAD_POSTER_SUCCESS:
      const updatedContentChannels = state.channels.map((c) => {
        if (c._id === action.channelId) {
          return {
            ...c,
            posterUrl: action.uploadImageResponse.fileUrl,
          };
        }
        return c;
      });
      return {
        ...state,
        isLoading: false,
        channels: updatedContentChannels,
      };

    default:
      return state;
  }
}

// Epics

const getContentChannels$: Epic = (action$) => {
  return action$.pipe(
    ofType(CONTENTCHANNEL_ACTIONS.GET_CONTENTCHANNELS),
    switchMap((a: GetContentChannelsAction) => {
      return contentChannelService.getChannels().pipe(
        map((channels) => {
          return GetContentChannelsSuccess(channels);
        }),
        catchError(handleError)
      );
    })
  );
};

const addContentChannel$: Epic = (action$) => {
  return action$.pipe(
    ofType(CONTENTCHANNEL_ACTIONS.ADD_CONTENTCHANNEL),
    switchMap((a: AddContentChannelAction) => {
      return contentChannelService.createChannel(a.data).pipe(
        mergeMap((response) => {
          return [
            GetContentChannels(),
            AddContentChannelSuccess(response),
            SetNotification("Channel added"),
          ];
        }),
        catchError(handleError)
      );
    })
  );
};

const addContentChannelFailed$: Epic = (action$) => {
  return action$.pipe(
    ofType(CONTENTCHANNEL_ACTIONS.ADD_CONTENTCHANNEL_FAILURE),
    map((a) => SetError(a.data))
  );
};

const updateContentChannel$: Epic = (action$) => {
  return action$.pipe(
    ofType(CONTENTCHANNEL_ACTIONS.UPDATE_CONTENTCHANNEL),
    switchMap((a: UpdateContentChannelAction) => {
      return contentChannelService.updateChannel(a.request).pipe(
        mergeMap((response) => {
          return [
            GetContentChannels(),
            UpdateContentChannelSuccess(response),
            SetNotification("Channel updated"),
          ];
        }),
        catchError(handleError)
      );
    })
  );
};

const updateContentChannelFailed$: Epic = (action$) => {
  return action$.pipe(
    ofType(CONTENTCHANNEL_ACTIONS.UPDATE_CONTENTCHANNEL_FAILURE),
    map((a) => SetError(a.data))
  );
};

const deleteContentChannel$: Epic = (action$) => {
  return action$.pipe(
    ofType(CONTENTCHANNEL_ACTIONS.DELETE_CONTENTCHANNEL),
    switchMap((a: DeleteContentChannelAction) => {
      return contentChannelService.deleteChannel(a.channelId).pipe(
        mergeMap((response) => {
          return [
            GetContentChannels(),
            DeleteContentChannelSuccess(response),
            SetNotification("Channel deleted"),
          ];
        }),
        catchError(handleError)
      );
    })
  );
};

const deleteContentChannelFailed$: Epic = (action$) => {
  return action$.pipe(
    ofType(CONTENTCHANNEL_ACTIONS.DELETE_CONTENTCHANNEL_FAILURE),
    map((a) => SetError(a.data))
  );
};

const uploadFile$: Epic = (action$) => {
  return action$.pipe(
    ofType(CONTENTCHANNEL_ACTIONS.UPLOAD_LOGO),
    switchMap((a: UploadFileAction) => {
      return contentChannelService
        .uploadFile(a.channelId, a.image, a.scope)
        .pipe(
          mergeMap((response) => {
            if (a.scope === FileUploadType.LOGO) {
              return [
                UploadLogoSuccess(a.channelId, response),
                SetNotification("Logo uploaded"),
              ];
            }
            if (a.scope === FileUploadType.POSTER) {
              return [
                UploadPosterSuccess(a.channelId, response),
                SetNotification("Poster uploaded"),
              ];
            }
            return [];
          }),
          catchError(handleError)
        );
    })
  );
};

export const contentChannelEpics = [
  getContentChannels$,
  addContentChannel$,
  addContentChannelFailed$,
  deleteContentChannel$,
  deleteContentChannelFailed$,
  updateContentChannel$,
  updateContentChannelFailed$,
  uploadFile$,
];
