import {
  Country,
  CountryCode,
  getCountry,
  TimezoneName,
} from "countries-and-timezones";
import { Minute } from "../types/HelperTypes";
import {
  CarouselAssetType,
  IRadioStation,
  SignageSlotAmount,
} from "../types/NendaTypes";
import { ApiAssetTypeName } from "../types/RedBeeTypes";

const USE_ENV = typeof process != "undefined";

const ASSET_TYPES: ApiAssetTypeName = {
  MOVIE: "Movie",
  TV_SHOW: "Tv show",
  EPISODE: "Episode",
  TV_CHANNEL: "Channel",
  CLIP: "Clip",
  LIVE_EVENT: "Live event",
  AD: "Advertisement",
  COLLECTION: "Collection",
  PODCAST: "Podcast",
  PODCAST_EPISODE: "Podcast Episode",
  OTHER: "Other",
};

const CAROUSEL_ASSET_TYPES: CarouselAssetType[] = [
  "MOVIE",
  "TV_SHOW",
  "EPISODE",
  "TV_CHANNEL",
  "CLIP",
  "LIVE_EVENT",
];

const DEFAULT_COUNTRY: Country = getCountry("SE");
const DEFAULT_COUNTRY_CODE: CountryCode = DEFAULT_COUNTRY.id;
const DEFAULT_TIMEZONE: TimezoneName = DEFAULT_COUNTRY.timezones[0];

const NATIONAL_RADIO_STATIONS: IRadioStation[] = [
  {
    id: "P1",
    externalId: "132",
    name: "P1 Sveriges Radio",
    url: "https://http-live.sr.se/p1-mp3-192",
  },
  {
    id: "P2",
    externalId: "163",
    name: "P2 Sveriges Radio",
    url: "https://http-live.sr.se/p2-mp3-192",
  },
  {
    id: "P3",
    externalId: "164",
    name: "P3 Sveriges Radio",
    url: "https://http-live.sr.se/p3-mp3-192",
  },
  {
    id: "P6",
    externalId: "166",
    name: "P6 Sveriges Radio",
    url: "https://sverigesradio.se/topsy/direkt/srapi/166.mp3",
  },
  {
    id: "P2 Musik",
    externalId: "2562",
    name: "P2 Musik",
    url: "https://sverigesradio.se/topsy/direkt/srapi/2562.mp3",
  },
  {
    id: "P4 Plus",
    externalId: "4951",
    name: "P4 Plus",
    url: "https://sverigesradio.se/topsy/direkt/srapi/4951.mp3",
  },
  {
    id: "SR_Finska",
    externalId: "226",
    name: "SR Finska",
    url: "https://sverigesradio.se/topsy/direkt/srapi/226.mp3",
  },
  {
    id: "SR Sápmi",
    externalId: "224",
    name: "SR Sápmi",
    url: "https://sverigesradio.se/topsy/direkt/srapi/224.mp3",
    disabled: true,
  },
];

const LOCAL_RADIO_STATIONS: IRadioStation[] = [
  {
    id: "P4 Blekinge",
    externalId: "213",
    name: "P4 Blekinge",
    url: "https://sverigesradio.se/topsy/direkt/srapi/213.mp3",
  },
  {
    id: "P4 Dalarna",
    externalId: "223",
    name: "P4 Dalarna",
    url: "https://sverigesradio.se/topsy/direkt/srapi/223.mp3",
  },
  {
    id: "P4 Gotland",
    externalId: "205",
    name: "P4 Gotland",
    url: "https://sverigesradio.se/topsy/direkt/srapi/205.mp3",
  },
  {
    id: "P4 Gävleborg",
    externalId: "210",
    name: "P4 Gävleborg",
    url: "https://sverigesradio.se/topsy/direkt/srapi/210.mp3",
  },
  {
    id: "P4 Goteborg",
    externalId: "212",
    name: "P4 Göteborg",
    url: "https://sverigesradio.se/topsy/direkt/srapi/212.mp3",
  },
  {
    id: "P4 Halland",
    externalId: "220",
    name: "P4 Halland",
    url: "https://sverigesradio.se/topsy/direkt/srapi/220.mp3",
  },
  {
    id: "P4 Jämtland",
    externalId: "200",
    name: "P4 Jämtland",
    url: "https://sverigesradio.se/topsy/direkt/srapi/200.mp3",
  },
  {
    id: "P4 Jönköping",
    externalId: "203",
    name: "P4 Jönköping",
    url: "https://sverigesradio.se/topsy/direkt/srapi/203.mp3",
  },
  {
    id: "P4 Kalmar",
    externalId: "201",
    name: "P4 Kalmar",
    url: "https://sverigesradio.se/topsy/direkt/srapi/201.mp3",
  },
  {
    id: "P4 Kristianstad",
    externalId: "211",
    name: "P4 Kristianstad",
    url: "https://sverigesradio.se/topsy/direkt/srapi/211.mp3",
  },
  {
    id: "P4 Kronoberg",
    externalId: "214",
    name: "P4 Kronoberg",
    url: "https://sverigesradio.se/topsy/direkt/srapi/214.mp3",
  },
  {
    id: "P4 Malmöhus",
    externalId: "207",
    name: "P4 Malmöhus",
    url: "https://sverigesradio.se/topsy/direkt/srapi/207.mp3",
  },
  {
    id: "P4 Sjuhärad",
    externalId: "206",
    name: "P4 Blekinge",
    url: "https://sverigesradio.se/topsy/direkt/srapi/206.mp3",
  },
  {
    id: "P4 Norrbotten",
    externalId: "209",
    name: "P4 Norrbotten",
    url: "https://sverigesradio.se/topsy/direkt/srapi/209.mp3",
  },
  {
    id: "P4 Skaraborg",
    externalId: "208",
    name: "P4 Skaraborg",
    url: "https://sverigesradio.se/topsy/direkt/srapi/208.mp3",
  },
  {
    id: "P4 Stockholm",
    externalId: "701",
    name: "P4 Stockholm",
    url: "https://sverigesradio.se/topsy/direkt/srapi/701.mp3",
  },
  {
    id: "P4 Sörmland",
    externalId: "202",
    name: "P4 Sörmland",
    url: "https://sverigesradio.se/topsy/direkt/srapi/202.mp3",
  },
  {
    id: "P4 Värmland",
    externalId: "204",
    name: "P4 Värmland",
    url: "https://sverigesradio.se/topsy/direkt/srapi/204.mp3",
  },
  {
    id: "P4 Väst",
    externalId: "219",
    name: "P4 Väst",
    url: "https://sverigesradio.se/topsy/direkt/srapi/219.mp3",
  },
  {
    id: "P4 Västerbotten",
    externalId: "215",
    name: "P4 Västerbotten",
    url: "https://sverigesradio.se/topsy/direkt/srapi/215.mp3",
  },
  {
    id: "P4 Västernorrland",
    externalId: "216",
    name: "P4 Västernorrland",
    url: "https://sverigesradio.se/topsy/direkt/srapi/216.mp3",
  },
  {
    id: "P4 Västmanland",
    externalId: "217",
    name: "P4 Västmanland",
    url: "https://sverigesradio.se/topsy/direkt/srapi/217.mp3",
  },
  {
    id: "P4 Örebro",
    externalId: "221",
    name: "P4 Örebro",
    url: "https://sverigesradio.se/topsy/direkt/srapi/221.mp3",
  },
  {
    id: "P4 Östergötland",
    externalId: "222",
    name: "P4 Östergötland",
    url: "https://sverigesradio.se/topsy/direkt/srapi/222.mp3",
  },
  {
    id: "P4 Södertälje",
    externalId: "5283",
    name: "P4 Södertälje",
    url: "https://sverigesradio.se/topsy/direkt/srapi/5283.mp3",
  },
];

const ALL_RADIO_STATIONS = [
  ...NATIONAL_RADIO_STATIONS,
  ...LOCAL_RADIO_STATIONS,
];

const ONE_SECOND = 1000; //1 sec in ms
const ONE_MINUTE = 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;
const ONE_WEEK = ONE_DAY * 7;

const DURATIONS = {
  ONE_MINUTE,
  ONE_HOUR,
  ONE_DAY,
  ONE_WEEK,
};

const Signage = () => {
  return {
    AD_MARKERS: [0, 6, 12, 18, 24, 30, 36, 42, 48, 54] as Minute[],
    CONTENT_SWITCH_INTERVAL: ONE_SECOND * ONE_MINUTE * 0, // 0 = no switching
    POLLING_RATE: USE_ENV
      ? process.env.EFFECT_CONTENT_POLLING_RATE_MS || ONE_SECOND * 30
      : ONE_SECOND * 30,
    SIGNAGE_SLOTS_PER_HOUR: 10 as SignageSlotAmount,
    SIGNAGE_SLOT_DURATION: ONE_MINUTE,
    SCHEDULE_NUMBER_OF_HOURS: 2,
  };
};

export default {
  ASSET_TYPES,
  CAROUSEL_ASSET_TYPES,
  ALL_RADIO_STATIONS,
  NATIONAL_RADIO_STATIONS,
  LOCAL_RADIO_STATIONS,
  Signage,
  DURATIONS,
  DEFAULT_COUNTRY,
  DEFAULT_COUNTRY_CODE,
  DEFAULT_TIMEZONE,
};
