import React from "react";
import "./Confirm.scss";

interface IConfirmProps {
  onConfirm(): void;
  onCancel(): void;
}

export const Confirm: React.FC<IConfirmProps> = ({ onConfirm, onCancel }) => {
  return (
    <div className="confirm">
      <div className="border p-2 inner-confirm bg-white">
        <h4>Are you sure?</h4>
        <button
          onClick={() => {
            onConfirm();
            onCancel();
          }}
          className="btn btn-secondary">
          Confirm
        </button>
        <button onClick={onCancel} className="btn btn-danger">
          Cancel
        </button>
      </div>
    </div>
  );
};
