import { ITemplate } from "../../types/NendaTypes";
import { httpClient, Service } from "./client";

class TemplatesService extends Service {

  getTemplates() {
    return httpClient.get("/templates", this.authHeader());
  }

  postTemplate(data: Partial<ITemplate>) {
    return httpClient.post("/templates", data, this.authHeader());
  }

  putTemplate(id: string, data: Partial<ITemplate>) {
    return httpClient.put(`/templates/${id}`, data, this.authHeader());
  }

  deleteTemplate(id: string) {
    return httpClient.delete(`/templates/${id}`, this.authHeader());
  }
}

export const templatesService = new TemplatesService();
