import { of } from "rxjs";
import { AppState, ErrorActions, ERROR_ACTIONS, SetErrorAction, SetLoadingAction } from "../types/redux";

export function SetError(data: any): SetErrorAction {
  return { type: ERROR_ACTIONS.SET_ERROR, currentError: data };
}

export function SetLoading(loading: boolean): SetLoadingAction {
  return { type: ERROR_ACTIONS.SET_LOADING, loading };
}

export const handleError = (err: any) => {
  return of(SetError(err.response ? err.response.message : err.message));
};

export default function errorReducer(state: AppState["error"] = { currentError: null, loading: false }, action: ErrorActions): AppState["error"] {
  switch (action.type) {
    case ERROR_ACTIONS.SET_ERROR:
      return {
        ...state,
        currentError: action.currentError,
        loading: false,
      };
    case ERROR_ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
}
