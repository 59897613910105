import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ColorPicker from "../../ColorPicker";
import FontSelect from "./FontSelect";

interface ClockProps {
  font: string;
  color: string;
  onFontChange: (font: string) => void;
  onColorChange: (color: string) => void;
}

const Clock: React.FC<ClockProps> = ({ font, color, onFontChange }) => {
  const [now, setNow] = useState(Date.now());

  useEffect(() => {
    var timerID = setInterval(() => tick(), 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  });

  function tick() {
    setNow(Date.now);
  }

  let time = new Date(now).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  const getTimeStyles = () => {
    let style: any = {};
    if (color) {
      style.color = color;
    }
    if (font) {
      style.fontFamily = font;
    }
    return style;
  };

  return (
    <Box>
      <Stack gap={2}>
        <Typography variant="h3" style={getTimeStyles()}>
          {time}
        </Typography>
        <FontSelect selectedFont={font} onSelect={onFontChange} />
      </Stack>
    </Box>
  );
};

export default Clock;
