import { Epic, ofType } from "redux-observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { carouselService } from "../http/carousel.service";
import {
  AppState,
  CarouselsActions,
  CAROUSEL_ACTIONS,
  CreateCarouselAction,
  DeleteCarouselAction,
  GetCarouselsAction,
  SetCaroueslsAction,
  UpdateCarouselAction,
} from "../types/redux";
import { handleError } from "./error.redux";

export function SetCarouesls(data: any): SetCaroueslsAction {
  return { type: CAROUSEL_ACTIONS.SET_CAROUSELS, carousels: data };
}

export function UpdateCarousel(carouselId: any, payload: any): UpdateCarouselAction {
  return { type: CAROUSEL_ACTIONS.UPDATE_CAROUSEL, carouselId, payload };
}

export function DeleteCarousel(carouselId: any): DeleteCarouselAction {
  return { type: CAROUSEL_ACTIONS.DELETE_CAROUSEL, carouselId };
}

export function GetCarousels(query: string): GetCarouselsAction {
  return { type: CAROUSEL_ACTIONS.GET_CAROUSELS, query };
}

export function CreateCarousel(payload: any): CreateCarouselAction {
  return { type: CAROUSEL_ACTIONS.CREATE_CAROUSEL, payload };
}

export default function carouselReducer(state: AppState["carousels"] = { carousels: [], loading: false }, action: CarouselsActions): AppState["carousels"] {
  switch (action.type) {
    case CAROUSEL_ACTIONS.SET_CAROUSELS:
      return {
        ...state,
        carousels: action.carousels,
        loading: false,
      };
    case CAROUSEL_ACTIONS.GET_CAROUSELS:
    case CAROUSEL_ACTIONS.CREATE_CAROUSEL:
    case CAROUSEL_ACTIONS.UPDATE_CAROUSEL:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

// Epics
const getCarousels$: Epic = (action$) => {
  return action$.pipe(
    ofType(CAROUSEL_ACTIONS.GET_CAROUSELS),
    switchMap(() => {
      return carouselService.getCarousels().pipe(
        map((carousels) => {
          return SetCarouesls(carousels);
        }),
        catchError(handleError)
      );
    })
  );
};

const updateCarousel$: Epic = (action$) => {
  return action$.pipe(
    ofType(CAROUSEL_ACTIONS.UPDATE_CAROUSEL),
    switchMap((a) => {
      return carouselService.updateCarousel(a.carouselId, a.payload).pipe(
        map(() => {
          return GetCarousels("");
        }),
        catchError(handleError)
      );
    })
  );
};

const deleteCarousel$: Epic = (action$) => {
  return action$.pipe(
    ofType(CAROUSEL_ACTIONS.DELETE_CAROUSEL),
    switchMap((a) => {
      return carouselService.deleteCarousel(a.carouselId).pipe(
        map(() => {
          return GetCarousels("");
        }),
        catchError(handleError)
      );
    })
  );
};

const createCarousel$: Epic = (action$) => {
  return action$.pipe(
    ofType(CAROUSEL_ACTIONS.CREATE_CAROUSEL),
    switchMap((a) => {
      return carouselService.createCarousel(a.payload).pipe(
        map(() => {
          return GetCarousels("");
        }),
        catchError(handleError)
      );
    })
  );
};

export const carouselEpics = [createCarousel$, deleteCarousel$, updateCarousel$, getCarousels$];
