import React from "react";
import "./Modal.scss";

interface IModalProps {
  visible?: boolean;
  children: any;
  onSubmit(): void;
  onClose(): void;
  title: string;
  confirmText?: string;
  disableSubmit?: boolean;
}

export const Modal: React.FC<IModalProps> = ({ visible, children, onSubmit, onClose, title, confirmText, disableSubmit }) => (
  <div className={"modal " + (visible ? "d-block" : "")}>
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {title}
          </h5>
          {onClose && (
            <button onClick={onClose} type="button" className="close">
              <span aria-hidden="true">&times;</span>
            </button>
          )}
        </div>
        <div className="modal-body">{children}</div>
        <div className="modal-footer">
          <button
            onClick={() => {
              onSubmit();
              onClose();
            }}
            disabled={!!disableSubmit}
            type="button"
            className="btn btn-secondary">
            {confirmText ? confirmText : "Save"}
          </button>
        </div>
      </div>
    </div>
  </div>
);
