import { NestedKeys } from "./HelperTypes";
import {
  Company,
  IAsset,
  IOrganizationUnit,
  IRoom,
  SimpleUser,
} from "./NendaTypes";

interface ISort<T, SortableFields extends keyof T> {
  sortBy: SortableFields;
  sortOrder: "asc" | "desc";
}

interface IPagination {
  page: number;
  pageSize: number;
}

export type IFilter<T> = {
  [K in keyof T]?: T[K];
};

export interface IListRequest<T, SortableFields extends keyof T> {
  pagination?: IPagination;
  filter?: IFilter<T>;
  sort?: ISort<T, SortableFields>;
}

export interface IListResponse<T> {
  data: T[];
  totalCount: number;
  filteredCount: number;
  page: number;
  pageSize: number;
}
export type PatchRequest<T> = {
  data: Partial<T>;
  unsetFields?: NestedKeys<T>[];
};

export enum UserSortableFields {
  EMAIL = "email",
  TYPE = "type",
}

export interface IUserListRequest
  extends IListRequest<SimpleUser, UserSortableFields> {}

export enum CompanySortableFields {
  NAME = "name",
}

export interface ICompanyListRequest
  extends IListRequest<Company, CompanySortableFields> {}

export enum RoomSortableFields {
  HOTEL = "hotel",
}

export enum AssetSortableFields {
  NAME = "name",
  CREATED_AT = "createdAt",
}
export interface IAssetListRequest
  extends IListRequest<IAsset, AssetSortableFields> {}

export interface IRoomListRequest
  extends IListRequest<IRoom, RoomSortableFields> {}

export enum OrganizationUnitSortableFields {
  PARENT = "parent",
  NAME = "name",
}

export interface IOrganizationUnitListRequest
  extends IListRequest<IOrganizationUnit, OrganizationUnitSortableFields> {}
