import {
  Company,
  IAsset,
  ISignagePromotion,
  NendaProduct,
} from "../../types/NendaTypes";
import {
  IAssetListRequest,
  ICompanyListRequest,
} from "../../types/RequestTypes";
import { httpClient, Service } from "./client";

interface UpdateCompanyAssetProps {
  companyId: string;
  assetId: string;
  product: NendaProduct;
  asset: Partial<IAsset>;
}
class CompanyService extends Service {
  getCompany(companyId: string) {
    return httpClient.get(`/company/${companyId}`, this.authHeader());
  }
  getCompanies(listRequest?: ICompanyListRequest) {
    const query = httpClient.listRequestToQuery(listRequest);

    return httpClient.get("/company" + query, this.authHeader());
  }
  createCompany(company: Company) {
    return httpClient.post("/company", company, this.authHeader());
  }
  updateCompany(companyId: string, company: Company) {
    return httpClient.put(`/company/${companyId}`, company, this.authHeader());
  }
  deleteCompany(companyId: string) {
    return httpClient.delete(`/company/${companyId}`, this.authHeader());
  }
  getUsers(companyId: string) {
    return httpClient.get(`/company/${companyId}/users`, this.authHeader());
  }
  getAssets(companyId: string, listRequest?: IAssetListRequest) {
    const query = httpClient.listRequestToQuery(listRequest);
    return httpClient.get(
      `/company/${companyId}/asset` + query,
      this.authHeader()
    );
  }
  createAsset(companyId: string, asset: IAsset) {
    return httpClient.post(
      `/company/${companyId}/asset`,
      { ...asset },
      this.authHeader()
    );
  }
  updateAsset({ companyId, assetId, product, asset }: UpdateCompanyAssetProps) {
    return httpClient.patch(
      `/company/${companyId}/asset/${product}/${assetId}`,
      { asset },
      this.authHeader()
    );
  }
  deleteAsset(companyId: string, product: NendaProduct, assetId: string) {
    return httpClient.delete(
      `/company/${companyId}/asset/${product}/${assetId}`,
      this.authHeader()
    );
  }

  uploadMediaFile(
    companyId: string,
    file: Blob,
    scope: string,
    assetType: string
  ) {
    let formData = new FormData();
    formData.append("scope", scope);
    formData.append("assetType", assetType);
    formData.append("file", file);

    return httpClient.put(
      `/company/${companyId}/file`,
      formData,
      this.authHeader(),
      false
    );
  }

  getPromotions(companyId: string) {
    return httpClient.get(
      `/company/${companyId}/signagepromotion`,
      this.authHeader()
    );
  }

  getPromotion(companyId: string, promotionId: string) {
    return httpClient.get(
      `/company/${companyId}/signagepromotion/${promotionId}`,
      this.authHeader()
    );
  }

  createPromotion(
    companyId: string,
    promotion: ISignagePromotion,
    applyToScreens: string[]
  ) {
    return httpClient.post(
      `/company/${companyId}/signagepromotion`,
      { promotion, applyToScreens },
      this.authHeader()
    );
  }

  deletePromotion(companyId: string, promotionId: string) {
    return httpClient.delete(
      `/company/${companyId}/signagepromotion/${promotionId}`,
      this.authHeader()
    );
  }
  updatePromotion(
    companyId: string,
    promotionId: string,
    promotionData: Partial<ISignagePromotion>,
    applyToScreens: string[]
  ) {
    return httpClient.patch(
      `/company/${companyId}/signagepromotion/${promotionId}`,
      { promotionData, applyToScreens },
      this.authHeader()
    );
  }
}

export const companyService = new CompanyService();
